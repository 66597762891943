// 印章柜相关接口
import request from '@/utils/request'

export const sealCabinetApi = {


    // 获取印章柜初始化状态
    checkCabinetInit(params) {
        return request({
            url: `pc/equipment/manage/checkCabinetInit`,
            method: 'GET',
            params
        })
    },

    // 柜机列表
    cabinetDetailList(params) {
        return request({
            url: `/pc/equipment/manage/cabinetDetailList`,
            method: 'GET',
            params
        })
    },
    // 格口信息列表
    boxDetailList(params) {
        return request({
            url: `/pc/equipment/manage/boxDetailList`,
            method: 'GET',
            params
        })
    },
    // 所属柜机下拉枚举
    getCabinetSelect(params) {
        return request({
            url: `/pc/equipment/manage/getCabinetSelect`,
            method: 'GET',
            params
        })
    },
    // 格口详情
    goodsNowDetail(params) {
        return request({
            url: `/pc/equipment/manage/goodsNowDetail`,
            method: 'GET',
            params
        })
    },
    // 格口盘点
    panDian(equipmentId) {
        return request({
            url: `/pc/equipment/manage/panDian/${equipmentId}`,
            method: 'POST',
        })
    },

}

