/*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-09-27 09:37:15
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-12-19 10:46:18
 * @FilePath: \yda_web_manage\src\views\equipmentManage\columns.js
 * @Description:
 */
const equipmentColumns = [
  {
    title: '终端编号',
    dataIndex: 'number',
    key: 'number',
    align: 'left',
    slots: {
      customRender: 'number'
    },
    width: 272
  },
  {
    title: '终端类型',
    dataIndex: 'equipmentType',
    key: 'equipmentType',
    align: 'left',
    slots: {
      customRender: 'equipmentType'
    },
    width: 174
  },
  {
    title: '版本号',
    dataIndex: 'firmwareEdition',
    key: 'firmwareEdition',
    align: 'left',
    slots: {
      customRender: 'firmwareEdition'
    },
    width: 160
  },
  {
    title: '所属组织',
    dataIndex: 'organization',
    key: 'organization',
    align: 'left',
    slots: {
      customRender: 'organization'
    },
    ellipsis: true,
    width: 286
  },
  {
    title: '终端型号',
    dataIndex: 'equipmentModel',
    key: 'equipmentModel',
    align: 'left',
    slots: {
      customRender: 'equipmentModel'
    },
    width: 192
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: {
      customRender: 'action'
    },
    fixed: 'right',
    width: 80
  }
]

// 印章柜详情-柜机信息表格配置数据
const machineColumns = [
  {
    title: '柜机名称',
    dataIndex: 'cabinetName',
    key: 'cabinetName',
    align: 'left',
    slots: {
      customRender: 'cabinetName'
    }
  },
  {
    title: '柜机编码',
    dataIndex: 'cabinetNumber',
    key: 'cabinetNumber',
    align: 'left'
  },
  {
    title: '柜机类型',
    dataIndex: 'cabinetType',
    key: 'cabinetType',
    align: 'left',
    slots: {
      customRender: 'cabinetType'
    }
  },
  {
    title: '格口数量',
    dataIndex: 'cabinetSum',
    key: 'cabinetSum',
    align: 'left'
  }
]

// 印章柜详情-格口信息表格配置数据
const outletsColumns = [
  {
    title: '所属柜机',
    dataIndex: 'cabinetNumber',
    key: 'cabinetNumber',
    align: 'left',
    slots: {
      customRender: 'cabinetNumber'
    }
  },
  {
    title: '格口编号',
    dataIndex: 'boxNumber',
    key: 'boxNumber',
    align: 'left',
    slots: {
      customRender: 'boxNumber'
    }
  },
  {
    title: '格口名称',
    dataIndex: 'boxName',
    key: 'boxName',
    align: 'left',
    slots: {
      customRender: 'boxName'
    }
  },
  {
    title: '物品类型',
    dataIndex: 'boxType',
    key: 'boxType',
    align: 'left',
    slots: {
      customRender: 'boxType'
    }
  },
  {
    title: '位置',
    key: 'position',
    align: 'left',
    slots: {
      customRender: 'position'
    }
  },
  {
    title: '在柜数量',
    dataIndex: 'nowNum',
    key: 'nowNum',
    align: 'left',
    slots: {
      customRender: 'nowNum'
    }
  },
  {
    title: '格口状态',
    dataIndex: 'boxStatus',
    key: 'boxStatus',
    align: 'left',
    slots: {
      customRender: 'boxStatus'
    }
  },
  {
    title: '箱门状态',
    dataIndex: 'boxOpenStatus',
    key: 'boxOpenStatus',
    align: 'left',
    slots: {
      customRender: 'boxOpenStatus'
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: {
      customRender: 'action'
    },
    width: 80
  }
]

// 印章柜详情-格口信息-详情弹窗配置
const modelColumns = [
  {
    ellipsis: true,
    title: '设备编号',
    dataIndex: 'equipmentNumber',
    key: 'equipmentNumber',
    align: 'left',
    slots: {
      customRender: 'equipmentNumber'
    }
  },
  {
    ellipsis: true,
    title: 'RFID标签',
    dataIndex: 'rfidNumber',
    key: 'rfidNumber',
    align: 'left',
    slots: {
      customRender: 'rfidNumber'
    }
  },
  {
    ellipsis: true,
    title: '物品名称',
    dataIndex: 'sealName',
    key: 'sealName',
    align: 'left',
    slots: {
      customRender: 'sealName'
    }
  },
  {
    ellipsis: true,
    title: '异常信息',
    dataIndex: 'exceptionMsg',
    key: 'exceptionMsg',
    align: 'left',
    slots: {
      customRender: 'exceptionMsg'
    }
  }
]
export { equipmentColumns, machineColumns, outletsColumns, modelColumns }
